import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Splash = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "manathome.png" }) {
        childImageSharp {
          fixed(width: 400, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return <Img fixed={data.file.childImageSharp.fixed}  />
}

export default Splash
