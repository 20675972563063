import React from "react"
import Layout from "../components/Layout/Layout"
import Pricing from "../components/PricingTable/PricingTable"
import HowItWorks from "../components/HowItWorks/HowItWorks"
import SEO from "../components/Seo/Seo"
import Splash from "../components/Splash/Splash"
import Img from "gatsby-image"
import { useStaticQuery } from "gatsby"

const OrganisersPage = function () {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "developer.png" }) {
        childImageSharp {
          fixed(width: 380, height: 330) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
  <Layout header={true} footer={true}>
    <SEO title="Home" />
    <section className="section">
      <div className="container">
        <div className="columns">
        <div className="column content is-two-thirds">
            <h1>For Event Organisers</h1>
            <div className="tabs">
              <ul>
                <li className="is-active">
                  <a>Get Started</a>
                </li>
                <li>
                  <a>Create your own passes</a>
                </li>
                <li>
                  <a>Use a ticket platform</a>
                </li>
              </ul>
            </div>
            <p>
              Hello. Do you want to sell tickets and host your event securely online with live video and more? We work with some of the worlds leading ticketing platforms, providing them with online event capabilities
            </p>
            <h2>Get Started</h2>
            <ol>
              <li>Sign up</li>
              <li>Choose a platform or create your own passes</li>
              <li>Connect your camera</li>
            </ol>
          </div>
          <div className="column is-hidden-mobile">
            <Splash/>
          </div>
        </div>
      </div> 
    </section>
  </Layout>
)
  }

export default OrganisersPage;
